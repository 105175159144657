import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../rootReducer";

interface IQuestionChoice {
  id: number;
  status: string;
  number: number;
  text_es: string;
  text_en: string;
  score: number;
}

interface IQuestion {
  id: number;
  status: string;
  number: number;
  text_es: string;
  text_en: string;
  type: string;
  scope: string;
  choices: IQuestionChoice[];
}

const emptyChoice: IQuestionChoice = {
  id: 0,
  status: "",
  number: 0,
  text_es: "",
  text_en: "",
  score: 0,
};

const emptyQuestion: IQuestion = {
  id: 0,
  status: "",
  number: 0,
  text_es: "",
  text_en: "",
  type: "",
  scope: "",
  choices: [emptyChoice],
};

const initialState = { questions: [emptyQuestion] };

const questionSlice = createSlice({
  name: "questionData",
  initialState,
  reducers: {
    clearQuestions: (state) => {
      state.questions = [];
    },
    saveQuestions: (state, action: PayloadAction<IQuestion[]>) => {
      state.questions = action.payload;
    },
  },
});
export const { saveQuestions, clearQuestions } = questionSlice.actions;

export default questionSlice.reducer;

export const selectQuestions = (state: RootState): IQuestion[] =>
  state.questionManagement.questions;
