import React, { useEffect, useState } from "react";

import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "app/hooks";

import { columnsForChoicesTable, columnsForQuestionsTable } from "../tableData";
import AdminTable from "../common/AdminTable";
import {
  createChoice,
  createQuestion,
  deleteChoice,
  deleteQuestion,
  editChoice,
  editQuestion,
  fetchAllQuestions,
} from "store/actions/questionActions";
import PungoModal from "pungo-ui/PungoModal";
import PungoInput from "pungo-ui/PungoInput";

import { selectQuestions } from "store/slices/question";

import styles from "./index.module.scss";

const AdminQuestionsTable: React.FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const questionsData = useSelector(selectQuestions);
  const [selectedQuestionId, setSelectedQuestionId] = useState<
    number | undefined
  >();
  const [selectedChoiceId, setSelectedChoiceId] = useState<
    number | undefined
  >();
  const [showModal, setShowModal] = useState(false);
  const [showChoiceModal, setShowChoiceModal] = useState(false);

  const [number, setNumber] = useState(0);
  const [type, setType] = useState("");
  const [spanishName, setSpanishName] = useState("");
  const [englishName, setEnglishName] = useState("");

  const [numberChoice, setNumberChoice] = useState(0);
  const [score, setScore] = useState(0);
  const [spanishChoiceName, setSpanishChoiceName] = useState("");
  const [englishChoiceName, setEnglishChoiceName] = useState("");

  const questionToEdit = questionsData.find(
    ({ id }) => id === selectedQuestionId
  );

  const choiceToEdit = questionToEdit?.choices.find(
    ({ id }) => id === selectedChoiceId
  );

  useEffect(() => {
    dispatch(fetchAllQuestions());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setNumber(questionToEdit?.number || 0);
    setType(questionToEdit?.type || "");
    setSpanishName(questionToEdit?.text_es || "");
    setEnglishName(questionToEdit?.text_en || "");
  }, [questionToEdit]);

  useEffect(() => {
    setNumberChoice(choiceToEdit?.number || 0);
    setScore(choiceToEdit?.score || 0);
    setSpanishChoiceName(choiceToEdit?.text_es || "");
    setEnglishChoiceName(choiceToEdit?.text_en || "");
  }, [choiceToEdit]);

  const handleOnDeleteConfirm = () => {
    if (selectedQuestionId) {
      dispatch(deleteQuestion(selectedQuestionId));
    }
  };
  const handleOnDeleteChoiceConfirm = () => {
    if (selectedQuestionId && selectedChoiceId) {
      dispatch(deleteChoice(selectedQuestionId, selectedChoiceId));
    }
  };

  const clearForm = () => {
    setSelectedQuestionId(undefined);
    setNumber(0);
    setType("");
    setSpanishName("");
    setEnglishName("");
  };

  const clearChoiceForm = () => {
    setSelectedChoiceId(undefined);
    setNumberChoice(0);
    setScore(0);
    setSpanishChoiceName("");
    setEnglishChoiceName("");
  };

  const handleOnClose = () => {
    setShowModal(false);
    clearForm();
  };
  const handleOnCloseChoiceModal = () => {
    setShowChoiceModal(false);
    clearChoiceForm();
  };

  const handleOnSave = () => {
    const questionPayload = {
      number,
      type,
      text_en: englishName,
      text_es: spanishName,
    };
    if (selectedQuestionId) {
      dispatch(editQuestion(selectedQuestionId, questionPayload));
      clearForm();
    } else {
      dispatch(createQuestion(questionPayload));
      clearForm();
    }
  };
  const handleOnSaveChoice = () => {
    const choicePayload = {
      number: numberChoice,
      text_en: englishChoiceName,
      text_es: spanishChoiceName,
      score,
      question: { number, type, text_en: englishName, text_es: spanishName },
    };
    if (selectedQuestionId && selectedChoiceId) {
      dispatch(editChoice(selectedQuestionId, selectedChoiceId, choicePayload));
      clearChoiceForm();
    } else {
      if (selectedQuestionId) {
        dispatch(createChoice(selectedQuestionId, choicePayload));
        clearChoiceForm();
      }
    }
  };

  const questionTypeOptions = ["SINGLE_SELECTION", "MULTIPLE_SELECTION"];

  const getCreateQuestionModal = () => {
    return (
      <PungoModal
        open={showModal}
        title={
          selectedQuestionId
            ? `${t("question.editTitle")}`
            : `${t("question.createTitle")}`
        }
        handleClose={handleOnClose}
        classNames={
          selectedQuestionId
            ? styles.modalParentForEdit
            : styles.modalParentForCreate
        }
        primaryAction={{
          label: selectedQuestionId
            ? t("admin.editLabel")
            : t("admin.createLabel"),
          onClick: handleOnSave,
          disabled: !number || !spanishName || !englishName,
        }}
        secondaryAction={{
          label: t("admin.cancelLabel"),
          onClick: handleOnClose,
        }}
      >
        <div className={styles.modal}>
          <div className={styles.inputs}>
            <PungoInput
              name={`${t("question.numberLabel")}`}
              value={number}
              onChange={setNumber}
              type="number"
            />
            <PungoInput
              name={`${t("question.typeLabel")}`}
              value={type}
              onChange={setType}
              type="select"
              options={questionTypeOptions.map((type) => {
                return { value: type, label: type };
              })}
            />
            <PungoInput
              name={`${t("question.spanishNameLabel")}`}
              value={spanishName}
              onChange={setSpanishName}
            />
            <PungoInput
              name={`${t("question.englishNameLabel")}`}
              value={englishName}
              onChange={setEnglishName}
            />
          </div>
          {selectedQuestionId && (
            <AdminTable
              columns={columnsForChoicesTable}
              data={questionToEdit?.choices || []}
              handleOnEdit={setShowChoiceModal}
              handleOnDelete={handleOnDeleteChoiceConfirm}
              handleOnSelection={setSelectedChoiceId}
              actions={["add", "delete", "edit"]}
              classNames={styles.choicesTable}
            />
          )}
        </div>
      </PungoModal>
    );
  };
  const getCreateChoiceModal = () => {
    return (
      <PungoModal
        open={showChoiceModal}
        title={
          selectedChoiceId
            ? `${t("question.editChoiceTitle")}`
            : `${t("question.createChoiceTitle")}`
        }
        handleClose={handleOnCloseChoiceModal}
        classNames={ styles.modalParentForChoice }
        primaryAction={{
          label: selectedChoiceId
            ? t("admin.editLabel")
            : t("admin.createLabel"),
          onClick: handleOnSaveChoice,
          disabled:
            !number || !spanishChoiceName || !englishChoiceName || !score,
        }}
        secondaryAction={{
          label: t("admin.cancelLabel"),
          onClick: handleOnCloseChoiceModal,
        }}
      >
        <div className={styles.modal}>
          <div className={styles.inputs}>
            <PungoInput
              name={`${t("question.numberLabel")}`}
              value={numberChoice}
              onChange={setNumberChoice}
              type="number"
            />
            <PungoInput
              name={`${t("question.scoreLabel")}`}
              value={score}
              onChange={setScore}
              type="number"
            />
            <PungoInput
              name={`${t("question.spanishChoiceNameLabel")}`}
              value={spanishChoiceName}
              onChange={setSpanishChoiceName}
            />
            <PungoInput
              name={`${t("question.englishChoiceNameLabel")}`}
              value={englishChoiceName}
              onChange={setEnglishChoiceName}
            />
          </div>
        </div>
      </PungoModal>
    );
  };

  return (
    <div>
      <AdminTable
        columns={columnsForQuestionsTable}
        data={questionsData || []}
        handleOnEdit={setShowModal}
        handleOnDelete={handleOnDeleteConfirm}
        handleOnSelection={setSelectedQuestionId}
        actions={["add", "delete", "edit"]}
      />
      {getCreateQuestionModal()}
      {getCreateChoiceModal()}
    </div>
  );
};

export default AdminQuestionsTable;
