import { AppDispatch } from "../index";
import { v4 as uuidv4 } from "uuid";
import { axiosInstance, getBaseUrlForAPI } from "../../connection";
import moment, { Moment } from "moment";
import { store } from "..";
import { clerUserSession, refresh } from "./authActions";
import { addAPIcall, completeAPIcall } from "../slices/api";
import { saveQuestions } from "store/slices/question";
import { displaySnackbarAPISuccess, getSnackbarError } from "./utils";

const questionURL = getBaseUrlForAPI("question");
interface ISurveyQuestion {
  number: number;
  text_es: string;
  text_en: string;
  type: string;
}
interface ISurveyChoice {
  number: number;
  text_es: string;
  text_en: string;
  score: number;
  question: ISurveyQuestion;
}

export const fetchAllQuestions =
  () =>
  (dispatch: AppDispatch): void => {
    const localRequest = () => {
      const id = `fetchAllQuestions-${uuidv4()}`;
      dispatch(addAPIcall(id));
      axiosInstance
        .get(`${questionURL}`)
        .then(({ data }) => {
          dispatch(saveQuestions(data));
        })
        .finally(() => {
          dispatch(completeAPIcall(id));
        });
    };

    const refreshToken = store.getState().authManagement.user?.refresh_token;
    let loginInfo = store.getState().authManagement.loginInfo;
    let actualTime :Moment = moment();

    if (actualTime.isAfter(loginInfo.sessionExpireTime)) {
      // session ended
      dispatch(clerUserSession());
    } else if (actualTime.isAfter(loginInfo.tokenExpireTime)) {
      // token needs refresh
      if(refreshToken) {
        dispatch(refresh({ refresh_token: refreshToken }, localRequest));
      } else {
        dispatch(clerUserSession());
      }
    } else {
      localRequest();
    }
  };

export const deleteQuestion =
  (questionId: number) =>
  (dispatch: AppDispatch): void => {
    const localRequest = () => {
      const id = `deleteQuestion-${uuidv4()}`;
      dispatch(addAPIcall(id));
      axiosInstance
        .delete(`${questionURL}/${questionId}`)
        .then(() => {
          dispatch(displaySnackbarAPISuccess("successMessage.questionDeleted"));
          dispatch(fetchAllQuestions());
        })
        .catch((error) => {
          getSnackbarError(error);
        })
        .finally(() => {
          dispatch(completeAPIcall(id));
        });
    };

    const refreshToken = store.getState().authManagement.user?.refresh_token;
    let loginInfo = store.getState().authManagement.loginInfo;
    let actualTime :Moment = moment();

    if (actualTime.isAfter(loginInfo.sessionExpireTime)) {
      // session ended
      dispatch(clerUserSession());
    } else if (actualTime.isAfter(loginInfo.tokenExpireTime)) {
      // token needs refresh
      if(refreshToken) {
        dispatch(refresh({ refresh_token: refreshToken }, localRequest));
      } else {
        dispatch(clerUserSession());
      }
    } else {
      localRequest();
    }
  };

export const createQuestion =
  (question: ISurveyQuestion) =>
  (dispatch: AppDispatch): void => {
    const localRequest = () => {
      const id = `createQuestion-${uuidv4()}`;
      dispatch(addAPIcall(id));
      axiosInstance
        .post(`${questionURL}`, question)
        .then(() => {
          dispatch(displaySnackbarAPISuccess("successMessage.questionCreated"));
          dispatch(fetchAllQuestions());
        })
        .catch((error) => {
          getSnackbarError(error);
        })
        .finally(() => {
          dispatch(completeAPIcall(id));
        });
    };

    const refreshToken = store.getState().authManagement.user?.refresh_token;
    let loginInfo = store.getState().authManagement.loginInfo;
    let actualTime :Moment = moment();

    if (actualTime.isAfter(loginInfo.sessionExpireTime)) {
      // session ended
      dispatch(clerUserSession());
    } else if (actualTime.isAfter(loginInfo.tokenExpireTime)) {
      // token needs refresh
      if(refreshToken) {
        dispatch(refresh({ refresh_token: refreshToken }, localRequest));
      } else {
        dispatch(clerUserSession());
      }
    } else {
      localRequest();
    }
  };

export const editQuestion =
  (questionId: number, question: ISurveyQuestion) =>
  (dispatch: AppDispatch): void => {
    const localRequest = () => {
      const id = `editQuestion-${uuidv4()}`;
      dispatch(addAPIcall(id));
      axiosInstance
        .put(`${questionURL}/${questionId}`, question)
        .then(() => {
          dispatch(displaySnackbarAPISuccess("successMessage.questionEdited"));
          dispatch(fetchAllQuestions());
        })
        .catch((error) => {
          getSnackbarError(error);
        })
        .finally(() => {
          dispatch(completeAPIcall(id));
        });
    };

    const refreshToken = store.getState().authManagement.user?.refresh_token;
    let loginInfo = store.getState().authManagement.loginInfo;
    let actualTime :Moment = moment();

    if (actualTime.isAfter(loginInfo.sessionExpireTime)) {
      // session ended
      dispatch(clerUserSession());
    } else if (actualTime.isAfter(loginInfo.tokenExpireTime)) {
      // token needs refresh
      if(refreshToken) {
        dispatch(refresh({ refresh_token: refreshToken }, localRequest));
      } else {
        dispatch(clerUserSession());
      }
    } else {
      localRequest();
    }
  };

export const createChoice =
  (questionId: number, question: ISurveyChoice) =>
  (dispatch: AppDispatch): void => {
    const localRequest = () => {
      const id = `createChoice-${uuidv4()}`;
      dispatch(addAPIcall(id));
      axiosInstance
        .post(`${questionURL}/${questionId}/choice`, question)
        .then(() => {
          dispatch(displaySnackbarAPISuccess("successMessage.choiceCreated"));
          dispatch(fetchAllQuestions());
        })
        .catch((error) => {
          getSnackbarError(error);
        })
        .finally(() => {
          dispatch(completeAPIcall(id));
        });
    };

    const refreshToken = store.getState().authManagement.user?.refresh_token;
    let loginInfo = store.getState().authManagement.loginInfo;
    let actualTime :Moment = moment();

    if (actualTime.isAfter(loginInfo.sessionExpireTime)) {
      // session ended
      dispatch(clerUserSession());
    } else if (actualTime.isAfter(loginInfo.tokenExpireTime)) {
      // token needs refresh
      if(refreshToken) {
        dispatch(refresh({ refresh_token: refreshToken }, localRequest));
      } else {
        dispatch(clerUserSession());
      }
    } else {
      localRequest();
    }
  };

export const editChoice =
  (questionId: number, choiceId: number, choice: ISurveyChoice) =>
  (dispatch: AppDispatch): void => {
    const localRequest = () => {
      const id = `editChoice-${uuidv4()}`;
      dispatch(addAPIcall(id));
      axiosInstance
        .put(`${questionURL}/${questionId}/choice/${choiceId}`, choice)
        .then(() => {
          dispatch(displaySnackbarAPISuccess("successMessage.choiceEdited"));
          dispatch(fetchAllQuestions());
        })
        .catch((error) => {
          getSnackbarError(error);
        })
        .finally(() => {
          dispatch(completeAPIcall(id));
        });
    };

    const refreshToken = store.getState().authManagement.user?.refresh_token;
    let loginInfo = store.getState().authManagement.loginInfo;
    let actualTime :Moment = moment();

    if (actualTime.isAfter(loginInfo.sessionExpireTime)) {
      // session ended
      dispatch(clerUserSession());
    } else if (actualTime.isAfter(loginInfo.tokenExpireTime)) {
      // token needs refresh
      if(refreshToken) {
        dispatch(refresh({ refresh_token: refreshToken }, localRequest));
      } else {
        dispatch(clerUserSession());
      }
    } else {
      localRequest();
    }
  };

export const deleteChoice =
  (questionId: number, choiceId: number) =>
  (dispatch: AppDispatch): void => {
    const localRequest = () => {
      const id = `deleteChoice-${uuidv4()}`;
      dispatch(addAPIcall(id));
      axiosInstance
        .delete(`${questionURL}/${questionId}/choice/${choiceId}`)
        .then(() => {
          dispatch(displaySnackbarAPISuccess("successMessage.choiceDeleted"));
          dispatch(fetchAllQuestions());
        })
        .catch((error) => {
          getSnackbarError(error);
        })
        .finally(() => {
          dispatch(completeAPIcall(id));
        });
    };

    const refreshToken = store.getState().authManagement.user?.refresh_token;
    let loginInfo = store.getState().authManagement.loginInfo;
    let actualTime :Moment = moment();

    if (actualTime.isAfter(loginInfo.sessionExpireTime)) {
      // session ended
      dispatch(clerUserSession());
    } else if (actualTime.isAfter(loginInfo.tokenExpireTime)) {
      // token needs refresh
      if(refreshToken) {
        dispatch(refresh({ refresh_token: refreshToken }, localRequest));
      } else {
        dispatch(clerUserSession());
      }
    } else {
      localRequest();
    }
  };