import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../rootReducer";

export interface ISurveyChoice {
  number: number;
  text_es: string;
  text_en: string;
}
export interface ISurveyAnswer {
  questionNumber: number;
  choicesNumbers: number[];
}

export interface ISurveyQuestion {
  number: number;
  text_es: string;
  text_en: string;
  type: string;
  choices: ISurveyChoice[];
}
export interface ISurvey {
  surveyStatus: "INCOMPLETE" | "COMPLETE";
  surveyScore: number;
  questions: ISurveyQuestion[];
  answers: ISurveyAnswer[];
}

const emptyChoice: ISurveyChoice = {
  number: 0,
  text_es: "",
  text_en: "",
};

const emptySurveyAnswer = {
  questionNumber: 0,
  choicesNumbers: [],
};

const emptyQuestions: ISurveyQuestion = {
  number: 0,
  text_es: "",
  text_en: "",
  type: "",
  choices: [emptyChoice],
};

const emptySurvey: ISurvey = {
  surveyStatus: "INCOMPLETE",
  surveyScore: -1,
  questions: [emptyQuestions],
  answers: [emptySurveyAnswer],
};

const initialState = {
  survey: emptySurvey,
  isSendingAnswer: false,
};

const surveySlice = createSlice({
  name: "surveyData",
  initialState,
  reducers: {
    saveSurvey: (state, action: PayloadAction<ISurvey>) => {
      state.survey = action.payload;
    },
    clearSurvey: (state) => {
      state.isSendingAnswer = false;
      state.survey.answers = [];
      state.survey.questions = [];
      state.survey.surveyScore = -1;
      state.survey.surveyStatus = "INCOMPLETE";
    },
    setAnswerLoader: (state, action: PayloadAction<boolean>) => {
      state.isSendingAnswer = action.payload;
    },
  },
});
export const { saveSurvey, setAnswerLoader, clearSurvey } = surveySlice.actions;

export default surveySlice.reducer;

export const selectSurvey = (state: RootState): ISurvey =>
  state.surveyManagement.survey;

export const selectAnswerLoader = (state: RootState): boolean =>
  state.surveyManagement.isSendingAnswer;
