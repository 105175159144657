import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Checkbox, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";

import PungoButton from "../../../../pungo-ui/PungoButton";
import styles from "./index.module.scss";
import { ISurveyQuestion } from "store/slices/survey";

interface SurveyCardProps {
  onChange: Dispatch<SetStateAction<number[]>>;
  question: ISurveyQuestion;
  onNext: () => void;
  onPrev: () => void;
  isLastQuestion?: boolean;
  isFirstQuestion?: boolean;
  isLoadingAnswer: boolean;
  initialChoices?: number[] | undefined;
}

const SurveyCard: React.FC<SurveyCardProps> = (props) => {
  const { t, i18n } = useTranslation();
  const language = i18n.language;
  const {
    question,
    onChange,
    onNext,
    onPrev,
    isLastQuestion = false,
    isFirstQuestion = false,
    isLoadingAnswer,
    initialChoices,
  } = props;
  const { number, text_en, text_es, type, choices } = question;

  const isSingleSelectionQuestion = type === "SINGLE_SELECTION";

  const [selectedChoices, setSelectedChoices] = useState<number[]>([]);

  useEffect(() => {
    if (initialChoices) {
      setSelectedChoices(initialChoices);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialChoices]);

  const hangleOnChange = ({ target }: any): any => {
    const choice = +target.value;
    if (isSingleSelectionQuestion) {
      setSelectedChoices([choice]);
    } else {
      const index = selectedChoices.indexOf(choice);
      if (index > -1) {
        selectedChoices.splice(index, 1);
        setSelectedChoices(selectedChoices.filter((opt) => opt !== choice));
      } else {
        setSelectedChoices((array) => [...array, choice]);
      }
    }
  };
  useEffect(() => {
    setTimeout(() => {
      onChange(selectedChoices.sort());
    }, 500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedChoices]);

  const clearSelection = () => setSelectedChoices([]);

  const handleOnNext = () => {
    clearSelection();
    onNext();
  };
  const handleOnPrev = () => {
    clearSelection();
    onPrev();
  };

  return (
    <div className={styles.container}>
      <div className={styles.question}>
        <span className={styles.number}>{`${number}.`}</span>
        <span>{language === "es" ? text_es : text_en}</span>
      </div>
      <div className={styles.options}>
        <RadioGroup>
          {choices &&
            choices.map(({ number, text_es, text_en }) => {
              return (
                <FormControlLabel
                  control={
                    isSingleSelectionQuestion ? (
                      <Radio checked={selectedChoices.includes(number)} />
                    ) : (
                      <Checkbox checked={selectedChoices.includes(number)} />
                    )
                  }
                  label={language === "es" ? text_es : text_en}
                  className={styles.optionRadio}
                  value={number}
                  onChange={hangleOnChange}
                  key={number}
                />
              );
            })}
        </RadioGroup>
      </div>
      <div className={styles.actions}>
        {!isFirstQuestion && (
          <PungoButton
            label={`${t("previousLabel")}`}
            disabled={isLoadingAnswer}
            type="text"
            onClick={handleOnPrev}
            color="secondary"
            icon={<ArrowBackIos />}
          />
        )}
        <PungoButton
          label={isLastQuestion ? `${t("finishLabel")}` : `${t("nextLabel")}`}
          disabled={!selectedChoices.length || isLoadingAnswer}
          type="text"
          onClick={handleOnNext}
          color="secondary"
          iconPosition="right"
          icon={<ArrowForwardIos />}
          classNames={styles.nextButton}
        />
      </div>
    </div>
  );
};

export default SurveyCard;
